import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Coal Excise Tax | How revenue works",
  "includeToc": true,
  "description": "In the United States, coal producers must pay a federal excise tax when they mine coal. Producers pay the tax when the coal is first sold or used. The tax originated in 1977 with the Black Lung Revenue Act.",
  "tags": ["How revenue works", "Coal", "Excise tax"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const TableHead = makeShortcode("TableHead");
const TableRow = makeShortcode("TableRow");
const TableCell = makeShortcode("TableCell");
const TableBody = makeShortcode("TableBody");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/how-revenue-works/"
      }}>{`How revenue works /`}</a></p>

    <h1 {...{
      "id": "Coal-Excise-Tax",
      "style": {
        "position": "relative"
      }
    }}>{`Coal Excise Tax`}</h1>
    <p>{`In the United States, coal producers must pay a federal excise tax when they mine coal. Producers pay the tax when the coal is first sold or used. The tax does not apply to lignite or to coal mined in the U.S. for export. Learn more about `}<a parentName="p" {...{
        "href": "/how-revenue-works/coal/"
      }}>{`how coal revenues work`}</a>{`.`}</p>
    <br />
    <p>{`This tax originated in 1977 with the Black Lung Revenue Act. The excise tax is the chief source of revenue for the `}<a parentName="p" {...{
        "href": "https://www.dol.gov/owcp/dcmwc/"
      }}>{`Black Lung Program `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{` and `}<a parentName="p" {...{
        "href": "https://www.dol.gov/sites/dolgov/files/general/budget/2021/CBJ-2021-V2-08.pdf"
      }}>{`Black Lung Disability Trust Fund (BLDTF) (PDF) `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`, which pays benefits to miners disabled by black lung disease, as well as their eligible survivors and dependents.`}</p>
    <br />
    <p>{`Coal Excise Tax payments are collected by the Internal Revenue Service and transferred to the BLDTF. Amounts in the BLDTF are available, as provided in appropriation acts, for benefit payments that are administered by the Department of Labor’s Division of Coal Mine Workers’ Compensation.`}</p>
    <br />
    <p><strong parentName="p">{`Coal Excise Tax revenue`}</strong></p>
    <Table mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Fiscal year</TableCell>
      <TableCell mdxType="TableCell">Surface mining</TableCell>
      <TableCell mdxType="TableCell">Subsurface mining</TableCell>
      <TableCell mdxType="TableCell">Total</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2023</TableCell>
      <TableCell mdxType="TableCell">$160,562,000</TableCell>
      <TableCell mdxType="TableCell">$143,788,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$304,350,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2022</TableCell>
      <TableCell mdxType="TableCell">$88,900,000</TableCell>
      <TableCell mdxType="TableCell">$88,842,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$177,742,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2021</TableCell>
      <TableCell mdxType="TableCell">$135,060,000</TableCell>
      <TableCell mdxType="TableCell">$188,754,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$323,814,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2020</TableCell>
      <TableCell mdxType="TableCell">$120,453,000</TableCell>
      <TableCell mdxType="TableCell">$131,213,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$251,666,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2019</TableCell>
      <TableCell mdxType="TableCell">$105,922,000</TableCell>
      <TableCell mdxType="TableCell">$114,371,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$220,293,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2018</TableCell>
      <TableCell mdxType="TableCell">$191,773,000</TableCell>
      <TableCell mdxType="TableCell">$190,356,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$382,129,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2017</TableCell>
      <TableCell mdxType="TableCell">$207,631,000</TableCell>
      <TableCell mdxType="TableCell">$224,094,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$431,725,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2016</TableCell>
      <TableCell mdxType="TableCell">$208,428,000</TableCell>
      <TableCell mdxType="TableCell">$226,515,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$434,943,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2015</TableCell>
      <TableCell mdxType="TableCell">$270,390,000</TableCell>
      <TableCell mdxType="TableCell">$270,561,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$540,951,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2014</TableCell>
      <TableCell mdxType="TableCell">$277,469,000</TableCell>
      <TableCell mdxType="TableCell">$296,936,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$574,405,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2013</TableCell>
      <TableCell mdxType="TableCell">$280,477,000</TableCell>
      <TableCell mdxType="TableCell">$290,189,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$570,661,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2012</TableCell>
      <TableCell mdxType="TableCell">$307,383,000</TableCell>
      <TableCell mdxType="TableCell">$281,232,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$588,615,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2011</TableCell>
      <TableCell mdxType="TableCell">$324,756,000</TableCell>
      <TableCell mdxType="TableCell">$298,922,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$623,678,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2010</TableCell>
      <TableCell mdxType="TableCell">$309,170,000</TableCell>
      <TableCell mdxType="TableCell">$300,898,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$610,068,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2009</TableCell>
      <TableCell mdxType="TableCell">$324,093,000</TableCell>
      <TableCell mdxType="TableCell">$307,045,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$631,138,000</strong></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">2008</TableCell>
      <TableCell mdxType="TableCell">$326,258,000</TableCell>
      <TableCell mdxType="TableCell">$318,332,000</TableCell>
      <TableCell mdxType="TableCell"><strong>$644,590,000</strong></TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <p>{`This data is from federal excise taxes reported to or collected by the Internal Revenue Service, Alcohol and Tobacco Tax and Trade Bureau, and the U.S. Customs Service. For more information, see `}<a parentName="p" {...{
        "href": "https://www.irs.gov/statistics/soi-tax-stats-excise-tax-statistics"
      }}>{`excise tax statistics `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`.`}</p>
    <h2 {...{
      "id": "Rates",
      "style": {
        "position": "relative"
      }
    }}>{`Rates`}</h2>
    <p>{`On January 1, 2020, the tax rate changed from $0.50 per ton for coal from subsurface mines and $0.25 per ton for coal from surface mines. Both rates were limited to a maximum of 2% of the coal’s selling price. The new tax rate is $1.10 per ton for coal from subsurface mines and $0.55 per ton for coal from surface mines. Both rates are limited to 4.4% of the coal's selling price.`}</p>
    <Table mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Type of mining</TableCell>
      <TableCell mdxType="TableCell">Excise tax rates</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Surface mining</TableCell>
      <TableCell mdxType="TableCell">
          $0.55 per ton or 4.4% of the sales price<br />
          (whichever is lower)
      </TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Subsurface mining</TableCell>
      <TableCell mdxType="TableCell">
          $1.10 per ton or 4.4% of the sales price<br />
          (whichever is lower)
      </TableCell>
    </TableRow>
  </TableBody>
    </Table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      